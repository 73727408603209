.item-products {
   display: flex;
  flex-direction: row;
}
/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .item-products {
    flex-direction: column;
    max-width: 75%;
  }
}
@media (max-width: 580px) {
  .item-products {
    flex-direction: column;
    max-width: 50%;
  }
}